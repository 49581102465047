import React, { useState } from "react";

import { ShopConfig } from "@utils/ssr";

import { defaultContext, ShopContext, ShopProviderType } from "./context";

type ShopProviderPops = {
  shopConfig: ShopConfig["shopConfig"];
  shop: ShopConfig["shop"];
  children: React.ReactNode;
};

const countryCodeToCurrency = {
  AU: "AUD",
  NZ: "NZD",
  GB: "GBP",
};

const countryCodeToDemonym = {
  AU: "Australian",
  NZ: "New Zealander",
  GB: "British",
};

const ShopProvider: React.FC<ShopProviderPops> = ({
  shopConfig,
  shop,
  children,
}) => {
  const [context] = useState<
    ShopConfig["shopConfig"] & ShopConfig["shop"] & ShopProviderType
  >({
    ...defaultContext,
    ...shopConfig,
    ...shop,
    currency:
      shopConfig && shopConfig.defaultCountry
        ? countryCodeToCurrency[shopConfig.defaultCountry.code]
        : "AUD",
    demonym:
      shopConfig && shopConfig.defaultCountry
        ? countryCodeToDemonym[shopConfig.defaultCountry.code]
        : "Australian",
  });

  return (
    <ShopContext.Provider value={context}>{children}</ShopContext.Provider>
  );
};

export default ShopProvider;
