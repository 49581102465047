import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";

import { defaultTheme } from "@styles";

import {
  UnconfirmedOrderBannerLink,
  UnConfirmedOrderBannerTextContainer,
} from "./styles";
import { IProps } from "./types";

const OverlayBox = ({ children, isSaleActive, overlay }) =>
  overlay ? (
    <Box
      position="relative"
      width="100%"
      top={isSaleActive ? "42" : "0"}
      zIndex={50}
      height={0}
    >
      {children}
    </Box>
  ) : (
    <Box position="relative" width="100%" top="0" zIndex={20} height={0}>
      {children}
    </Box>
  );

export const UnconfirmedOrderBanner: React.FC = ({
  overlay,
  total,
  isSaleActive,
}: IProps) => (
  <OverlayBox isSaleActive={isSaleActive} overlay={overlay}>
    <Center
      backgroundColor="white"
      borderBottom="1px"
      opacity={0.9}
      paddingY="4"
      height={["16", "12", "12"]}
    >
      <UnConfirmedOrderBannerTextContainer>
        <Text
          color={
            defaultTheme.colors.components.UnConfirmedOrderBanner.textColor
          }
          fontSize={["20", "22", "22"]}
        >
          You have{" "}
          {total === 1
            ? "an unconfirmed order."
            : `${total} unconfirmed orders.`}
        </Text>

        <Text
          color={
            defaultTheme.colors.components.UnConfirmedOrderBanner.textColor
          }
          fontSize={["20", "22", "22"]}
          pl={1}
        >
          Please
          <UnconfirmedOrderBannerLink href="/dashboard/orders" target="_blank">
            review and confirm now
          </UnconfirmedOrderBannerLink>
        </Text>
      </UnConfirmedOrderBannerTextContainer>
    </Center>
  </OverlayBox>
);
