import { Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { ReactSVG } from "react-svg";

import xBlackImg from "images/x-black.svg";

const messages = {
  item: "Sorry that item is not available anymore",
  designers: "Sorry we don’t have that designer",
};

const removeQueryParam = (param, router) => {
  const { pathname, query } = router;
  const params = new URLSearchParams(query);
  params.delete(param);
  router.replace({ pathname, query: params.toString() }, undefined, {
    shallow: true,
  });
};

const RedirectedFromBanner = ({
  redirectedFrom,
}: {
  redirectedFrom: string;
}) => {
  const router = useRouter();
  return (
    <Box
      w="full"
      bgColor="white"
      borderBottom="1px"
      borderTop="1px"
      paddingY="4"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box />
      <Box mx="auto" textAlign="center">
        {messages[redirectedFrom] || "sorry"}
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          cursor="pointer"
          onClick={() => {
            removeQueryParam("redirectedFrom", router);
          }}
          position="relative"
          right={[2, null, null, 20]}
        >
          <ReactSVG src={xBlackImg.src} />
        </Box>
      </Box>
    </Box>
  );
};

export default RedirectedFromBanner;
