import styled from "@emotion/styled";

export const UnConfirmedOrderBannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    padding-bottom: 5px;
  }
`;

export const UnconfirmedOrderBannerLink = styled.a`
  text-decoration: underline;
  padding-left: 5px;
`;
