import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";

import { IProps } from "./types";

const OverlayBox = ({ overlay, children }) =>
  overlay ? (
    <Box position="relative" width="100%" top="0" zIndex={50} height={0}>
      {children}
    </Box>
  ) : (
    children
  );

export const SaleBanner: React.FC<IProps> = ({ message, overlay }) =>
  message && (
    <OverlayBox overlay={overlay}>
      <Center
        backgroundColor="white"
        borderBottom="1px"
        opacity={0.95}
        height={["70px", null, null, null, null, "40px"]}
        paddingX="10px"
      >
        <Text
          align="center"
          lineHeight={["30px", null, null, null, null, "50px"]}
          fontSize={["18px", null, null, null, null, "20px"]}
        >
          {message}
        </Text>
      </Center>
    </OverlayBox>
  );
