import React, { useState } from "react";

import { getMappedItems } from "@utils/menu";
import { ShopConfig } from "@utils/ssr";

import { defaultContext, MenuContext } from "./context";

type MenuProviderProps = {
  mainMenu: ShopConfig["mainMenu"];
  footer: ShopConfig["footer"];
  children: React.ReactNode;
};

const MainMenuProvider: React.FC<MenuProviderProps> = ({
  mainMenu,
  footer,
  children,
}) => {
  const [context] = useState({
    mainMenu: {
      ...defaultContext.mainMenu,
      ...mainMenu,
      items: mainMenu?.items ? getMappedItems(mainMenu.items) : undefined,
    },
    footer: {
      ...defaultContext.footer,
      ...footer,
      items: footer?.items ? getMappedItems(footer.items) : undefined,
    },
  });

  return (
    <MenuContext.Provider value={context}>{children}</MenuContext.Provider>
  );
};

export default MainMenuProvider;
